import React from "react";
import {CostTransport} from "./ProductInfo/CostTransport";
import {ProductAttributeProvider} from "../../state";
import {client} from "../../../Infrastructure/models/client";
import {QueryClientProvider} from "@tanstack/react-query";

interface ProductInfoProps {
    productSku: string;
    categorySettings: Record<string, string>;
}

const queryClient = client();

export const ProductInfo: React.FC<ProductInfoProps> = ({productSku, categorySettings}) => {
    return (
        <QueryClientProvider client={queryClient}>
            <ProductAttributeProvider productSku={productSku}>
                <CostTransport categorySettings={categorySettings} />
            </ProductAttributeProvider>
        </QueryClientProvider>
    );
}
