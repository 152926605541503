import React, {useState} from 'react';
import {useCheckoutState} from "../modules/Checkout/state/context/CheckoutProvider";
import {useWindowSize} from './useWindowSize';
import {LoadingIcon} from './assets/LoadingIcon';

export const CardItem = ({product}) => {
    const {isMobile} = useWindowSize();
    const {handleAddToCart, addingToCart} = useCheckoutState();

    const [qty, setQty] = useState(1);

    if (!product) return null;

    const formatPrice = (price) => {
        return new Intl.NumberFormat('ro-RO', { style: 'currency', currency: 'Lei' }).format(price);
    };

    const regularPriceValue = product?.price_range?.minimum_price?.regular_price.value;
    const finalPriceValue = product?.price_range?.minimum_price?.final_price.value;

    const regularPriceFormatted = formatPrice(regularPriceValue);
    const finalPriceFormatted = formatPrice(finalPriceValue);

    const discountPercentage = regularPriceValue && finalPriceValue
        ? ((regularPriceValue - finalPriceValue) / regularPriceValue) * 100
        : 0;
    const discountValue = regularPriceValue - finalPriceValue
    const discountValueFormatted = formatPrice(discountValue);

    const productUrl = `${window.BASE_URL}${product.url_rewrites[0]?.url || ''}`;

    return (
        <div
            className={'relative bg-white rounded-lg mb-5 mt-5 h-auto ' +
                'md:hover:shadow-custom-box-shadow md:hover:scale-105 md:transition-all md:duration-300 ease-in-out ' +
                'grid grid-row-2 p-1 md:p-5 max-w-[310px]'}>
            {
                product?.stock_status === "OUT_OF_STOCK" && (
                    <div
                        className={'absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 z-10'}>
                        <span
                            className={'react-css uppercase text-rose-700 text-2xl font-black text-center mb-48 '}>
                            {'Stoc Epuizat'}
                        </span>
                    </div>
                )
            }
            {regularPriceValue !== finalPriceValue ? (
                <div>
                    <p className={'react-css absolute h-[18px] w-[60px] rounded-t-xl left-0 top-0 text-white m-3 text-xs font-bold text-center bg-black-friday-btn'}>
                        {`- ${(discountPercentage).toFixed(0)}%`}
                    </p>
                    <p className={'react-css absolute h-[18px] w-[60px] rounded-b-xl left-0 top-[18px] text-custom-gray m-3 text-xs font-bold text-center bg-first-btn-bg'}>
                        {`PROMO`}
                    </p>
                </div>
            ) : null}
            <a
                className={'row-span-1 h-52 flex justify-center items-center font-montserrat w-full'}
                href={productUrl}
            >
                <img src={product?.customProductImageUrl?.url} alt={product?.small_image?.label}
                     className={'h-auto object-contain object-center w-11/12 '} loading="lazy"/>
            </a>

            <div className={'row-span-1 grid grid-row-4 gap-2 md:gap-4 h-auto w-auto'}>
                <a className={'h-[65px] md:h-[90px] text-black hover:underline active:text-black md:mt-4'}
                   href={productUrl}>
                    <h3 className={'react-css text-sm md:text-lg font-semibold md:font-bold font-montserrat leading-5 md:leading-7 text-black text-center'}>
                        {product?.name.length > 50 ? `${product?.name.substring(0, 50)}...` : product.name}
                    </h3>
                </a>
                <div className={'react-css mt-2 h-[45px] md:h-[60px] flex justify-center w-full'}>
                    {/* Check if the custom attribute 'farapret' has the value 'fara_cos_pret' */}
                    {
                        product?.custom_attributes.find(attr => attr.attribute_code === 'farapret')?.value === 'fara_cos_pret' ||
                        regularPriceValue === 0 ? (
                        <div></div>
                    ) : (
                        regularPriceValue === finalPriceValue ? (
                            <p className={'react-css text-custom-gray text-sm md:text-lg font-bold font-montserrat'}>
                                {regularPriceFormatted}
                            </p>
                        ) : (
                            <div className={'react-css flex flex-col w-full items-center'}>
                                <div className={'react-css flex flex-wrap justify-evenly items-center w-full'}>
                                    <p className={'react-css font-bold text-text-gray text-xs md:text-sm line-through font-montserrat'}>
                                        {regularPriceFormatted}
                                    </p>
                                    <p className={'react-css bg-main-orange p-1 md:p-2 font-montserrat font-bold text-custom-gray ' +
                                        'text-xs md:text-sm leading-none rounded-xl'}>
                                        {'- '} {discountValueFormatted}
                                    </p>
                                </div>
                                <p className={'react-css text-sm md:text-lg font-bold text-red-500 font-montserrat'}>
                                    {finalPriceFormatted}
                                </p>
                            </div>
                        )
                    )}
                </div>

                <div className={'flex justify-center items-center h-[50px]'}>
                    <p className={
                        `react-css ${product?.custom_attributes.find(attr => attr.attribute_code === 'status_stock')?.value === 'Ultimul produs din stoc' ? 'bg-black-friday-btn' : 'bg-custom-green'} ` +
                        `p-2 font-montserrat font-bold text-white text-center text-sm leading-none rounded-xl w-fit `
                    }>
                        {product?.custom_attributes.find(attr => attr.attribute_code === 'status_stock')?.value}
                    </p>
                </div>

                <div className={'react-addToCart flex justify-center'}>
                    {product?.type_id != 'simple' || product?.custom_attributes.find(attr => attr.attribute_code === 'farapret')?.value != 'pret_stoc' ? (
                        <button
                            className={
                                'flex justify-center items-center h-[40px] md:h-[50px] min-w-[144px] md:min-w-[210px] w-full bg-main-orange focus:outline-none ' +
                                'focus:bg-main-orange active:bg-main-orange text-white text-sm md:text-base font-montserrat ' +
                                'font-bold rounded-full w-4/5 md:m-0 hover:bg-main-orange hover:text-white ' +
                                'focus:text-white active:text-white shadow-first-btn-shadow border-main-orange transition-all duration-300 ease-in-out'
                            }
                            onClick={() => window.location.href = `${window.BASE_URL}${product.url_rewrites[0]?.url || ''}`}
                        >
                            Vezi detalii
                        </button>
                    ) : (
                        <button
                            className={
                                'flex justify-center items-center h-[40px] md:h-[50px] min-w-[144px] md:min-w-[210px] w-full bg-main-orange focus:outline-none ' +
                                'focus:bg-main-orange active:bg-main-orange text-white text-sm md:text-base font-montserrat ' +
                                'font-bold rounded-full w-4/5 md:m-0 hover:bg-main-orange hover:text-white hover:border-none ' +
                                'focus:text-white active:text-white shadow-first-btn-shadow border-none transition-all duration-300 ease-in-out'
                            }
                            onClick={() => handleAddToCart({sku: product?.sku, quantity: qty ? qty : 1})}
                        >
                            {product?.sku === addingToCart ? (
                                <LoadingIcon/>
                            ) : (
                                <>
                                    <svg className={'mr-5'}
                                         xmlns="http://www.w3.org/2000/svg" height="18" width="18"
                                         viewBox="0 0 512 512">
                                        <path fill="#ffffff"
                                              d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/>
                                    </svg>
                                    {!isMobile ? (
                                        <span>{'Adauga in cos'}</span>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" height="28" width="30"
                                             viewBox="0 0 576 512">
                                            <path fill="#ffffff"
                                                  d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
                                        </svg>
                                    )}
                                </>
                            )}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
