import React, { useState, useMemo } from 'react';
import { ExpandButton } from '../../../../../general';
import { useProductAttributes } from '../../../state';

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

function addAltToImages(html, productName) {
    // Căutăm toate tag-urile <img> care nu au atribut alt sau au alt gol
    const imgRegex = /<img(?![^>]*alt=["'](?:(?!\s*["']).)+["'])[^>]*>/gi;

    return html.replace(imgRegex, (match) => {
        // Verificăm dacă există deja un atribut alt gol
        if (match.includes('alt=""') || match.includes("alt=''")) {
            // Înlocuim atributul alt gol cu noul alt
            return match.replace(/alt=["'']/, `alt="Imagine pentru ${productName}"`);
        } else {
            // Adăugăm noul atribut alt înainte de închiderea tag-ului
            return match.replace(/>$/, ` alt="Imagine pentru ${productName}">`);
        }
    });
}

export const Prezentare = () => {
    const { productName, loading, shortDescription } = useProductAttributes() || {};
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleButtonClick = () => {
        toggleExpand();
        const element = document.getElementById('productDescription');
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    const processedDescription = useMemo(() => {
        if (shortDescription && productName) {
            const decodedHtml = decodeHtml(shortDescription);
            return addAltToImages(decodedHtml, productName);
        }
        return null;
    }, [shortDescription, productName]);

    return (
        <div className={'relative rounded-xl bg-white md:mb-20 mb-10'} id={'productDescription'}>
            <div className={`react-css overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-full' : 'max-h-[450px]'} p-6`}>
                <div className={'react-css'}>
                    <h2 className={'react-css text-2xl font-black text-left text-custom-gray font-montserrat mb-8 flex'}>
                        <span className={'react-css w-2/5 md:block hidden'}>Prezentare</span>
                        <span className={'react-css text-custom-gray font-montserrat font-bold text-center text-2xl'}>
                            {productName}
                        </span>
                    </h2>
                </div>
                {processedDescription ? (
                    <div dangerouslySetInnerHTML={{ __html: processedDescription }} />
                ) : (
                    <p>Loading...</p>
                )}
            </div>
            {/* opacity effect over the closed div */}
            <div
                className={`absolute top-0 left-0 w-full h-full transition-all duration-300 ${isExpanded ? 'hidden' : 'bg-custom-gradient'} rounded-2xl`}>
            </div>
            {/* The "See More/Less" button */}
            <div className={'react-css flex items-center justify-center md:mb-0 mb-5'}>
                <ExpandButton
                    isExpanded={isExpanded}
                    onClick={handleButtonClick}
                    expandedText="Ascunde descrierea"
                    collapsedText="Vezi toata descrierea"
                />
            </div>
        </div>
    );
}
